import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetData } from '../services/getData/getData';

const initialState = {
  promocode: null,
  discount: null,
  status: 'idle',
  errorMessage: null,
};

export const sendInputSale = createAsyncThunk('send/sendInputSale', async (promocode) => {
  const request = new GetData();
  const data = await request.postData(
    'sale/',
    'POST',
    { sale_promocode: promocode },
    { 'Content-Type': 'application/json' },
  );
  return data;
});

const inputSaleSlice = createSlice({
  name: 'inputSale',
  initialState,
  reducers: {
    setPromocode: (state, action) => {
      state.promocode = action.payload;
    },
    resetPromocode: (state, action) => {
      state.promocode = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendInputSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendInputSale.fulfilled, (state, action) => {
        state.status = 'done';
        state.discount = action.payload;
      })
      .addCase(sendInputSale.rejected, (state, action) => {
        state.status = 'error';
        state.discount = action.payload;
      });
  },
});

export const { setPromocode, resetPromocode } = inputSaleSlice.actions;
export const { reducer: inputSaleSliceReducer } = inputSaleSlice;
