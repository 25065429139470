import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const lngs = {
  ru: { nameOfLanguage: 'Русский' },
  kz: { nameOfLanguage: 'Казахский' },
}

i18n.use(LanguageDetector).use(initReactI18next).use(Backend).init({
  fallbackLng: 'ru',
  debug: false,
  saveMissing: true,
})

export default i18n
export { lngs }
