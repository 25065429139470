import './socialMedia.scss';
import './mediaSocialMedia.scss';

const SocialMedia = (props) => {
  const { specificWrapperClass, specificItemClass } = props;
  const socialMediaClasses = `social-media ${specificWrapperClass ? specificWrapperClass : ''}`;

  return (
    <div className={socialMediaClasses}>
      <SingleMedia linkClass={specificItemClass} link='https://t.me/UFCRussia' iconClass='icon-telegram' />
      <SingleMedia linkClass={specificItemClass} link='https://vk.com/ufc' iconClass='icon-vk' />
      <SingleMedia linkClass={specificItemClass} link='https://vt.tiktok.com/ZSdwRqA9E/' iconClass='icon-tiktok' />
    </div>
  );
};

const SingleMedia = (props) => {
  //   console.log(props)
  const { iconClass, linkClass, link } = props;
  const linkClasses = `social-media__link ${linkClass ? linkClass : 'social-media__link_white'}`;
  return (
    <a href={link} className={linkClasses}>
      <span className={iconClass}></span>
    </a>
  );
};

export { SingleMedia };
export default SocialMedia;
