import _apiURL from '../../config/index';
export class GetData {
  request = async (url, method = 'GET', body = null, headers = { 'Content-Type': 'application/json' }) => {
    try {
      const response = await fetch(_apiURL + url, { method, headers });
      if (!response.ok || response.status >= 400) {
        console.log('error occurred in request Class');
        console.log(`Could not fetch ${_apiURL + url}, status: ${response.status}`);
      } else if (response.ok || response.status < 400) {
        try {
          const data = await response.json();
          return data;
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  postData = async (url, method = 'POST', body, headers) => {
    try {
      const response = await fetch(_apiURL + url, { method, body: JSON.stringify(body), headers });
      if (!response.ok || response.status >= 400) {
        console.log('error occurred in request Class');
        console.log(`Could not fetch ${_apiURL + url}, status: ${response.status}`);
        throw new Error('Произошла ошибка, попробуйт позже');
      } else if (response.ok || response.status < 400) {
        try {
          const data = await response.json();
          return data;
        } catch (e) {
          console.log(e);
          throw new Error('Произошла ошибка');
        }
      }
    } catch (e) {
      console.log(e);
      throw new Error('Произошла ошибка, попробуйт позже');
    }
  };
}
