const formMessages = {
  requiredInput: 'Это обязательное поле',
  incorrectCode: 'Неправильный код',
  isNotEqualEmails: 'Введенные Email не совпадают',
  isNotEqualPasswords: 'Введенные пароли не совпадают',
  promocodeDoNotExist: 'Промокода не существует',
  emailIncorrect: 'Email указан неверно',
  oldPasswordIsNotEqualNew: 'Пароли не совпадают',
  minLenghtPassword: 'Длина пароля не менее 4 символов',
  errorSignIn: 'Неверный логин или пароль',
  maxLenghtPassword: 'Длина пароля не более 32 символов',
  userHasExisted: 'Пользователь с данным email уже есть',
  tooManyRequests: 'Ошибка при попытке создания пользователя. Попробуйте позже...',
  serviceIntegrationError: 'Ошибка при попытке создания пользователя. Попробуйте позже...',
  emailDoNotRegistrated: 'Пользователь с такой почтой не зарегистрирован',
  userHasAccountOfficialSite:
    'данный email загеристрирован на официальном сайте',
  validatePattern:
    'Необходимы заглавные латинские буквы, цифры и символы !@#$%^&*',
  userDoesNotHaveRecurrent:
    'Данный пользователь не имеет реккурентной подписки',
  somethingGoneWrong: 'Что-то пошло не так. Попробуйте позже',
}

const months = [
  { eng: 'Jan', lng: 'января', numberOfMonth: '01' },
  { eng: 'Feb', lng: 'февраля', numberOfMonth: '02' },
  { eng: 'Mar', lng: 'марта', numberOfMonth: '03' },
  { eng: 'Apr', lng: 'апреля', numberOfMonth: '04' },
  { eng: 'May', lng: 'мая', numberOfMonth: '05' },
  { eng: 'Jun', lng: 'июня', numberOfMonth: '06' },
  { eng: 'Jul', lng: 'июля', numberOfMonth: '07' },
  { eng: 'Aug', lng: 'августа', numberOfMonth: '08' },
  { eng: 'Sep', lng: 'сентября', numberOfMonth: '09' },
  { eng: 'Oct', lng: 'октября', numberOfMonth: '10' },
  { eng: 'Nov', lng: 'ноября', numberOfMonth: '11' },
  { eng: 'Dec', lng: 'декабря', numberOfMonth: '12' },
]

const styles = {
  btnOpacityClass: 'button_modal-opacity',
  redSpinnerClass: 'spinner_red',
  whiteSpinnerClass: 'spinner_white',
}

export { formMessages, months, styles }
