import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetData } from '../services/getData/getData';

const initialState = {
  qr: null,
  status: 'idle',
};

export const postPaymentData = createAsyncThunk('payment/postPaymentData', async ({ body, headers }) => {
  const getData = new GetData();
  const response = await getData.postData('tinkoff/generate_sbp_qr/', 'POST', body, headers);
  return await response;
});

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postPaymentData.pending, (state) => {
        state.status = 'loading';
        state.qr = null;
      })
      .addCase(postPaymentData.fulfilled, (state, action) => {
        state.status = 'done';
        state.qr = action.payload;
      })
      .addCase(postPaymentData.rejected, (state) => {
        state.status = 'error';
        state.qr = null;
      });
  },
});
