import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetData } from '../services/getData/getData';

const initialState = {
  data: [],
  status: 'idle',
};

export const fetchLandingMedia = createAsyncThunk('media/fetchLandingMedia', async () => {
  const request = new GetData().request('main/');
  return await request;
});

export const landingMediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLandingMedia.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLandingMedia.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'done';
      })
      .addCase(fetchLandingMedia.rejected, (state) => {
        state.status = 'error';
      });
  },
});
