const routes = {
  main: '/',
  mainTariffs: '/#tariffs',
  mainFaq: '/#faq',
  b2b: '/b2b',
  b2bFaq: '/b2b/#faq',
  b2bForms: '/b2b/#b2b-forms',
  account: '/account',
  yearResults: 'year-results',
  privacy: '/privacy',
  agreement: '/agreement',
  thanksForPayment: '/spasibo',
  tickets: '/tickets',
  ufc308vote: '/ufc308vote',
  ufc308voteThankyou: '/ufc308vote/thankyou',
};

export default routes;
