import { Transition } from 'react-transition-group'
import { useState, useRef } from 'react'

import './spinner.scss'

// component which is shown during loading a page or block
// typeOfSpinner can be spinner_red and spinner_white
const Spinner = (props) => {
  const { styles } = props
  const [inProp, setInProp] = useState(false)
  const nodeRef = useRef(null)
  const { typeOfSpinner, wrapperStyles } = styles
  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={1000}>
      <div style={wrapperStyles}>
        <div className={`spinner ${typeOfSpinner ? typeOfSpinner : ''}`}> </div>
      </div>
    </Transition>
  )
}

export default Spinner
