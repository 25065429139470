import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { landingMediaSlice } from '../slices/landginMediaSlice';
import { paymentChoiceSlice } from '../slices/paymentChoiceSlice';
import { paymentSlice } from '../slices/sbpPaySlice';
import { inputSaleSliceReducer } from '../slices/inputSaleSice';

const rootReducer = combineReducers({
  media: landingMediaSlice.reducer,
  paymentChoice: paymentChoiceSlice.reducer,
  payment: paymentSlice.reducer,
  inputSale: inputSaleSliceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
