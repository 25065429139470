import { lazy } from 'react'
import { Link } from 'react-router-dom'
import routes from '../../routes'

import logo_white from '../../assets/icons/logo/logo_white.svg'
import logo_black from '../../assets/icons/logo/logo_black.svg'
import logo_red from '../../assets/icons/logo/logo_red.svg'

const LogoLink = (props) => {
  const { colorOfLogo, logoClass } = props
  let logo
  switch (colorOfLogo) {
    case 'red':
      logo = logo_red
      break
    case 'white':
      logo = logo_white
      break
    case 'black':
      logo = logo_black
      break

    default:
      logo = logo_white
      break
  }
  return (
    <>
      <Link to={routes.main}>
        <img
          src={logo}
          alt="UFC Fight Pass"
          className={logoClass ? logoClass : ''}
        />
      </Link>
    </>
  )
}

export default LogoLink
